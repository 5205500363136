import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import QuiSommesNous from "../componets/QuiSommesNous/QuiSommesNous"
import "./globals.css"
import { graphql, useStaticQuery } from "gatsby"

export const query = graphql`
  query {
    strapiAboutUs {
      presentation_title
      presentation_content
      to_know_title
      to_know_content
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Home(props) {
  const metas = {
    title: "Qui sommes nous - Se Former.re",
    metatitle: "Présentation et bon à savoir sur Se Former.re",
    description:
      "Tout ce qu'il faut savoir sur le site d'annonces de formation en continu ou formation professionnelle  à La Réunion,  Drom Com, Océan Indien -seformer.re",
    keywords:
      "formation, stage, formation en continu, La Réunion, annonnce,  Drom Com, Océan Indien",
  }
  const { data } = props
  const infos = data.strapiAboutUs

  return (
    <div>
      <SiteMetadata metas={metas} />
      <Header {...props} logo={data.logo}/>
      <QuiSommesNous infos={infos} />
      <Footer />
    </div>
  )
}
