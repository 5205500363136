import React from 'react';
import images from '../../assets/images';
import './QuiSommesNous.css'
import {Link} from "gatsby";
import {stripTags} from "../../services/helpers";

var md = require('markdown-it')({
    html: true,
    xhtmlOut: true,
    breaks: true,
});

function QuiSommesNous(props) {
    const {infos} = props;
    return (
        <>
            <section className="NavsingleFormation">
                <div className='container container-seformer'>
                    <ul className="breadcrumb-single-formation">
                        <li><Link to="/">Accueil</Link></li>
                        <li><img src={images.breadcrumbsSeparator} alt='icone breadcrumbs separator'/></li>
                        <li className="active">Qui sommes-nous</li>
                    </ul>
                </div>
            </section>
            <div className="pageQuisommesnous pt-2 pb-5">
                <div className="titlepage-interne">
                    <div className='container container-seformer'>
                        <div className="d-flex justify-content-center">
                            <h1 className="text-center title-section mx-auto my-3">Qui <span>sommes-nous</span> <img
                                src={images.iconeQuisommesnous} alt='Qui sommes-nous'/></h1>
                        </div>
                    </div>
                </div>
                <div className='container container-seformer'>
                    <div className="ml-120">
                        <div className="my-5 section-qui-sommes-nous">
                            <h2 className="h3quisommesnous">{infos.presentation_title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.presentation_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-4 section-qui-sommes-nous">
                            <h2 className="h3quisommesnous">{infos.to_know_title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.to_know_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuiSommesNous;